<template>
  <v-container grid-list-md>
    <v-row dense>
      <v-col cols="12"> <b>Nombres y apellidos: </b>{{ person.contacto_emergencia? person.contacto_emergencia:'sin dato' }} </v-col>

      <v-col cols="12">
        <b>Teléfono/celular: </b
        >{{ person.telefono_emergencia ? person.telefono_emergencia : 'sin dato' }}
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
export default {
  props: { person: Object },
};
</script>