<template>
  <v-container grid-list-md>
    <v-row dense>
      <v-col cols="12"> <b>Código de persona: </b>{{ person.codigo }} </v-col>
      <v-col cols="12"> <b>Apellidos y nombres: </b>{{ person.nombre }} </v-col>
      <v-col cols="12">
        <b>Cédula de identidad: </b>{{ person.cedula }}
        {{ person.expedido }}
      </v-col>
      <v-col cols="12">
        <b>Fecha de nacimiento: </b>{{ person.fecha_nacimiento }}
      </v-col>
      <v-col cols="12">
        <b>Genero: </b>{{ person.genero == "F" ? "FEMENIMO" : "MASCULINO" }}
      </v-col>
      <v-col cols="12" sm="6">
        <b>Estado civil: </b>{{ person.estado_civil }}
      </v-col>
      <v-col cols="12" sm="6">
        <b>Tipo de sangre: </b
        >{{ person.grupo_sanguineo ? person.grupo_sanguineo : "sin dato" }}
      </v-col>
      <v-col cols="12">
        <b>Teléfono/celular: </b
        >{{ person.telefono ? person.telefono : "sin dato" }}
      </v-col>
      <v-col cols="12">
        <b>Correo electrónico: </b
        >{{ person.correo ? person.correo : "sin dato" }}
      </v-col>
      <v-col cols="12" v-if="person.rda">
        <b>R.D.A.: </b>{{ person.rda }}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: { person: Object },
};
</script>
