<template>
  <div>
    <v-container grid-list-xl fluid>
      <header-title title="Reporte personal docente"></header-title>
      <p class="grey--text text--darken-1">Docente de ESFM o UA.</p>
      <v-row>
        <v-col cols="12" sm="4">
          <v-autocomplete
            label="Institución"
            :items="institutions"
            v-model="options.institution_id"
            item-text="nombre"
            item-value="codigo"
            @change="getTeacherStaff"
            clearable
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            class="mr-1"
            label="Buscar"
            v-model="options.search"
            append-icon="mdi-magnify"
            hide-details
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                small
                color="error"
                class="mr-1"
                v-bind="attrs"
                v-on="on"
                @click="reportTeacherStaff"
                :disabled="teacherStaffs.length == 0"
              >
                PDF
              </v-btn>
            </template>
            <span>Docentes</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                small
                color="info"
                class="mr-1"
                v-bind="attrs"
                v-on="on"
                @click="reportTeacherStaffGeneral"
                :loading="pdf_loading"
              >
                PDF
              </v-btn>
            </template>
            <span>Docentes en general</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                small
                color="warning"
                v-bind="attrs"
                v-on="on"
                @click="reportQuantityTeacher"
              >
                PDF
              </v-btn>
            </template>
            <span>Cantidad docentes</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="teacherStaffs"
        :search="options.search"
        :items-per-page="50"
        :footer-props="{
          'items-per-page-options': [25, 50, 100, 150, -1],
        }"
        calculate-widths
        item-key="name"
        class="elevation-2"
        v-cloak
      >
        <template v-slot:[`item.numeration`]="{ item }">
          <td>
            {{ teacherStaffs.indexOf(item) + 1 }}
          </td>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <td>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  class="mr-1"
                  type="button"
                  color="info"
                  :loading="btn_loading"
                  @click="showDetail(item.codigo)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-view-list-outline</v-icon>
                </v-btn>
              </template>
              <span>Ver detalle</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="item.tipo_cargo == 'DE' || item.tipo_cargo == 'DU'"
                  small
                  type="button"
                  color="success"
                  :loading="btn_loading"
                  @click="showSchedule(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-table-large</v-icon>
                </v-btn>
                <v-btn v-else small :disabled="true">
                  <v-icon>mdi-table-large</v-icon>
                </v-btn>
              </template>
              <span>Ver Horario</span>
            </v-tooltip>
          </td>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog v-model="detailDialog" scrollable max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-3" primary-title>
          <span class="headline">Detalle</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <h4 class="teal--text">Datos personales</h4>
          <personal-information :person="teacher" />
          <h4 class="teal--text">Formación y cargo</h4>
          <v-container grid-list-md>
            <v-row dense>
              <v-col cols="12">
                <b>Profesión: </b>
                {{ teacher.profesion? teacher.nombre_profesion: 'sin dato' }}
              </v-col>
              <v-col cols="12">
                <b>Tipo de designación: </b>
                {{ teacher.tipo_designacion ? teacher.tipo_designacion : "sin dato" }}
              </v-col>
              <v-col cols="12">
                <b>Tipo de cargo: </b>
                {{ teacher.tipo_cargo ? teacher.tipo_cargo : "sin dato" }}
              </v-col>
              <v-col cols="12">
                <b>Cargo: </b>
                {{ teacher.cargo ? teacher.cargo : "sin dato" }}
              </v-col>
            </v-row>
          </v-container>
          <h4 class="teal--text">Ubicación</h4>
          <person-location-data :person="teacher" />

          <h4 class="teal--text">Contacto de emergencia</h4>
          <personal-contacto-emergencia :person="teacher" />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click.native="detailDialog = false"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="scheduleDialog"
      v-model="scheduleDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Horario del/la docente</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn color="red" dark @click="scheduleDialog = false">
              Cerrar
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-row class="ma-1">
          <v-col cols="12" sm="6">
            <v-row>
              <v-col cols="12" sm="8" class="mt-2">
                <v-autocomplete
                  outlined
                  label="Periodo y Gestión"
                  :items="periodYears"
                  v-model="year_id"
                  item-text="periodo_gestion"
                  item-value="periodo_gestion"
                  @change="getTeacherSchedule"
                  hide-details
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6">
            <h4 class="teal--text text--darken-2">Datos del/la docente</h4>
            <v-row class="mt-2">
              <v-col class="py-0" cols="12" sm="12">
                Código:
                <span class="grey--text text--darken-2">{{
                  person.codigo
                }}</span></v-col
              >
              <v-col class="py-0" cols="12" sm="12">
                Apellidos y Nombres:
                <span class="grey--text text--darken-2">{{
                  person.nombre
                }}</span></v-col
              >
              <v-col class="py-0" cols="12" sm="12"
                >ESFM/UA:
                <span class="grey--text text--darken-2">{{
                  person.esfm_ua
                }}</span></v-col
              >
            </v-row>
          </v-col>
        </v-row>
        <v-divider class="mt-5"></v-divider>
        <div class="mx-4" v-if="unitTrainingAssigneds.length > 0">
          <h4 class="mt-2 mb-1 pb-1 teal--text text--darken-2">
            Unidades de Formación asignadas
          </h4>
          <v-row>
            <v-col cols="12">
              <div class="v-data-table v-data-table--dense theme--light">
                <div class="v-data-table__wrapper">
                  <table>
                    <thead>
                      <tr>
                        <th class="text-left">#</th>
                        <th class="text-left">Especialidad</th>
                        <th class="text-left">Año de formación</th>
                        <th class="text-left">Paralelo</th>
                        <th class="text-left">Estudiantes</th>
                        <th class="text-left">Código UF</th>
                        <th class="text-left">Unidad de formación</th>
                        <th class="text-left">Horas semana</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in unitTrainingAssigneds"
                        :key="index"
                      >
                        <td>{{ index + 1 }}</td>
                        <td>{{ item.especialidad }}</td>
                        <td>{{ item.semestre }}</td>
                        <td>{{ item.paralelo }}</td>
                        <td>{{ item.estudiantes }}</td>
                        <td>
                          {{ item.cod_modulo }} |
                          {{ item.sistema == "A" ? "Anual" : "Semestral" }}
                        </td>
                        <td>{{ item.uf }}</td>
                        <td>
                          {{ item.horas_semana }} (anual
                          {{ item.horas_gestion }})
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td
                          class="text-center grey--text text--darken-2"
                          colspan="4"
                        >
                          <b>Total estudiantes</b>
                        </td>
                        <td class="grey--text text--darken-2">
                          <b>{{ totalStudent }}</b>
                        </td>
                        <td
                          class="text-center grey--text text--darken-2"
                          colspan="2"
                        >
                          <b>Total horas semana</b>
                        </td>
                        <td class="grey--text text--darken-2">
                          <b
                            >{{ totalHours }} (mes {{ totalHours * 4 }} hrs.)</b
                          >
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="mx-4" v-if="workshopTrainingAssigneds.length > 0">
          <h4 class="mt-2 mb-1 pb-1 teal--text text--darken-2">
            Taller de Formación complementaria
          </h4>
          <v-row>
            <v-col cols="12">
              <div class="v-data-table v-data-table--dense theme--light">
                <div class="v-data-table__wrapper">
                  <table>
                    <thead>
                      <tr>
                        <th class="text-left">#</th>
                        <th class="text-left">Año de formación</th>
                        <th class="text-left">Código</th>
                        <th class="text-left">Taller</th>
                        <th class="text-left">Horas semana</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in workshopTrainingAssigneds"
                        :key="index"
                      >
                        <td>{{ index + 1 }}</td>
                        <td>
                          {{ item.des_semestre }} - {{ item.nom_modulo }} -
                          {{ item.paralelo }}
                        </td>
                        <td>{{ item.cod_taller }}</td>
                        <td>{{ item.nom_taller }}</td>
                        <td>{{ item.horas_semana }}</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td class="text-center" colspan="4">
                          <b>Total horas semana</b>
                        </td>
                        <td class="text-rights">
                          <b>{{ totalWorkshop }}</b>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="mx-4" v-if="workshopTrainingAssigneds.length == 0">
          <h4 class="mt-2 mb-1 pb-1 teal--text text--darken-2">
            Horario del/la docente
          </h4>
          <v-row>
            <v-col cols="12">
              <div class="v-data-table theme--light">
                <div class="v-data-table__wrapper">
                  <table>
                    <tbody class="schedule">
                      <tr
                        v-for="(item, index) in teacherSchedules"
                        :key="index"
                      >
                        <td>{{ item[0] }}</td>
                        <td>{{ item[1] }}</td>
                        <td>{{ item[2] }}</td>
                        <td>{{ item[3] }}</td>
                        <td>{{ item[4] }}</td>
                        <td>{{ item[5] }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
/* eslint-disable no-console */
import HeaderTitle from "@/components/HeaderTitle";
import PersonalInformation from "@/components/PersonalInformation";
import PersonLocationData from "@/components/PersonLocationData.vue";
import Storage from "../../utils/storage";
import Endpoint from "../../utils/environment";
import PDFService from "../../utils/pdf";
import Service from "../../services/request";
import { mapActions } from "vuex";

import PersonalContactoEmergencia from "@/components/PersonalContactoEmergencia";

export default {
  name: "teacher-staff",
  components: {
    HeaderTitle,
    PersonalInformation,
    PersonLocationData,
    PersonalContactoEmergencia,
  },
  data() {
    return {
      loading: false,
      btn_loading: false,
      pdf_loading: false,
      headers: [
        { text: "#", value: "numeration", align: "left", sortable: false },
        { text: "Código ", value: "codigo", sortable: false },
        { text: "Apellidos y Nombres ", value: "nombre" },
        { text: "Cédula Identidad ", value: "cedula" },
        { text: "RDA ", value: "rda", sortable: false },
        { text: "Cargo ", value: "cargo" },
        { text: "Teléfono", value: "telefono", sortable: false },
        { text: "Acciones ", value: "actions", sortable: false },
      ],
      institutions: [],
      periodYears: [],
      unitTrainingAssigneds: [],
      workshopTrainingAssigneds: [],
      teacherSchedules: [],
      teacherStaffs: [],
      teacher: {
        canton: "",
        cedula: "",
        codigo: "",
        correo: "",
        departamento: "",
        domicilio: "",
        estado: null,
        estado_civil: "",
        expedido: "",
        fecha_etado: "",
        fecha_nacimiento: "",
        genero: "",
        grupo_sangui: "",
        localidad: "",
        municipio: "",
        nombre: "",
        profesion: "",
        provincia: "",
        rda: "",
        telefono: "",
        modalidad: "",
      },
      person: {
        codigo: "",
        nombre: "",
        esfm_ua: "",
      },
      options: {
        search: "",
        institution_id: "",
      },
      year_id: null,
      detailDialog: false,
      scheduleDialog: false,
      user: null,
      serve: "",
    };
  },
  computed: {
    totalStudent() {
      return this.unitTrainingAssigneds.reduce(
        (acc, item) => acc + item.estudiantes,
        0
      );
    },
    totalHours() {
      return this.unitTrainingAssigneds.reduce(
        (acc, item) => acc + item.horas_semana,
        0
      );
    },
    totalWorkshop() {
      return this.workshopTrainingAssigneds.reduce(
        (acc, item) => acc + item.horas_semana,
        0
      );
    },
  },
  created() {
    this.serve = Endpoint.getServe();
  },
  mounted() {
    this.user = Storage.getUser();
    if (this.user && this.user.rid == 1) {
      this.getInstitutionParameter();
    } else {
      this.$router.replace({ name: "dashboard" });
    }
  },
  methods: {
    ...mapActions(["toast"]),
    getInstitutionParameter() {
      Service.svcInstitutionParameter("get")
        .then((response) => {
          this.institutions = response.data;
        })
        .catch((error) => {
          console.error("No cargo los registros", error);
        });
    },
    getTeacherStaff() {
      this.loading = true;
      Service.svcTeacherStaff("post", this.options)
        .then((response) => {
          this.loading = false;
          this.teacherStaffs = response.data;
        })
        .catch((error) => {
          this.loading = false;
          console.error("Error al cargar registros", error);
        });
    },
    showDetail(person_code) {
      this.btn_loading = true;
      Service.svcPersonById("get", { person_code: person_code })
        .then((response) => {
          this.btn_loading = false;
          this.teacher = response.data;
          this.detailDialog = true;
        })
        .catch((error) => {
          this.btn_loading = false;
          console.error("No cargo el registro", error);
        });
    },
    showSchedule(person) {
      this.person.codigo = person.codigo;
      this.person.nombre = person.nombre;
      const esfmUa = this.institutions.find(
        (item) => item.codigo == this.options.institution_id
      );
      this.person.esfm_ua = esfmUa ? esfmUa.nombre : "";
      if (this.periodYears.length === 0) {
        this.btn_loading = true;
        Service.svcPeriodYears("get")
          .then((response) => {
            this.periodYears = response.data[0];
            const year = this.periodYears.find((item) => item.vigente == 1);
            this.year_id = year ? year.periodo_gestion : "";
            this.btn_loading = false;
            this.getTeacherSchedule();
          })
          .catch(() => {
            this.btn_loading = false;
          });
      } else {
        const year = this.periodYears.find((item) => item.vigente == 1);
        this.year_id = year ? year.periodo_gestion : "";
        this.getTeacherSchedule();
      }
      this.scheduleDialog = true;
    },
    getTeacherSchedule() {
      this.unitTrainingAssigneds = [];
      this.workshopTrainingAssigneds = [];
      this.teacherSchedules = [];
      Service.svcTeacherSchedule("post", {
        person_code: this.person.codigo,
        period_years: this.year_id,
        institution_id: this.options.institution_id,
      }).then((response) => {
        this.unitTrainingAssigneds = response.data[0];
        this.workshopTrainingAssigneds = response.data[1];
        const schedules = response.data[2];
        const hours = response.data[3];
        this.teacherSchedules = [
          ["HORAS", "LUNES", "MARTES", "MIERCOLES", "JUEVES", "VIERNES"],
        ];
        hours.forEach((item, index) => {
          this.teacherSchedules.push([item.hor_nombre])[index + 1];
        });

        schedules.forEach((item) => {
          this.teacherSchedules[item.posFila][item.cod_dia] = `${
            item.cod_modulo
          } | ${item.sistema == "A" ? "Anual" : "Semestral"} | Paralelo ${
            item.paralelo
          }`;
        });
      });
    },
    reportTeacherStaff() {
      this.pdf_loading = true;
      const institution = this.institutions.find(
        (item) => item.codigo === this.options.institution_id
      );
      PDFService.pdfStaff("DOCENTE", institution, this.teacherStaffs);
      this.pdf_loading = false;
    },
    reportTeacherStaffGeneral() {
      this.pdf_loading = true;
      Service.svcTeacherStaffGeneral("get")
        .then((response) => {
          PDFService.pdfStaffGeneral("DOCENTE", response.data);
          this.pdf_loading = false;
        })
        .catch(() => {
          this.pdf_loading = false;
        });
    },
    reportQuantityTeacher() {
      this.pdf_loading = false;
      Service.svcQuantityTeacher("get")
        .then((response) => {
          PDFService.quantityStaffByInstitution("DOCENTES", response.data);
          this.pdf_loading = false;
        })
        .catch(() => {
          this.pdf_loading = false;
        });
    },
  },
};
</script>
