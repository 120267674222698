<template>
  <v-container grid-list-md>
    <v-row dense>
      <v-col cols="12">
        <b>Dirección: </b>{{ person.direccion ? person.direccion : "sin dato" }}
      </v-col>
      <v-col cols="12"><b>Departamento: </b>{{ person.departamento }}</v-col>
      <v-col cols="12">
        <b>Provincia: </b>{{ person.provincia ? person.provincia : "sin dato" }}
      </v-col>
      <v-col cols="12">
        <b>Localidad: </b>{{ person.localidad ? person.localidad : "sin dato" }}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: { person: Object },
};
</script>
